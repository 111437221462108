.heat-sources-wrapper {
  display: grid;
  gap: 8px;
}

.heat-source-controls {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.baseplate-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  .baseplate {
    position: relative;
    box-sizing: content-box;
    background-color: var(--app-background-color);
    border: 2px solid var(--app-blue-gray-lightest);

    .baseplate-origin-marker:before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: -5px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: var(--app-blue-gray-lightest);
    }

    .baseplate-origin-label:before {
      position: absolute;
      color: var(--app-blue-gray);
      text-align: center;
      font-size: 0.8rem;
      content: 'Origin (0,0)';
      bottom: -24px;
    }

    .baseplate-size-marker:before {
      content: '';
      position: absolute;
      top: -5px;
      right: -5px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: var(--app-blue-gray-lightest);
    }

    .baseplate-size-label {
      position: absolute;
      color: var(--app-blue-gray);
      text-align: center;
      font-size: 0.8rem;
      right: 0;
      top: -24px;
    }

    .heat-source {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: default;
      box-sizing: border-box;
      border: 2px solid var(--app-blue-gray-lighter);
      background-color: var(--app-blue-gray-lightest);
      z-index: 10;
      &.selected {
        cursor: move;
        border-color: var(--app-primary-color);
        background-color: var(--app-primary-tint);
        z-index: 100;
      }
      &.error {
        border: 2px solid var(--app-error-border-color);
        background: var(--app-error-background-color);
      }
      .label {
        font-weight: 700;
      }
      .power {
        font-size: 0.7rem;
      }
      .popover-wrapper {
        position: absolute;
        top: 0;
        right: 4px;
        display: none;
      }
      &:hover,
      &.selected {
        .popover-wrapper {
          display: block;
        }
      }
      &.selected .heatsource-origin-marker:before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: -5px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: var(--app-primary-color);
      }
      &.error .heatsource-origin-marker:before {
        background: var(--app-error-border-color);
      }
    }
  }
}

@arrow-width: 22px;

.fin-direction,
.flow-direction {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80px;

  .arrow {
    width: 0;
    height: 0;
    margin: auto;

    &.arrow-up {
      border-left: @arrow-width solid transparent;
      border-right: @arrow-width solid transparent;
      border-bottom: @arrow-width solid var(--app-background-color);
    }

    &.arrow-down {
      border-left: @arrow-width solid transparent;
      border-right: @arrow-width solid transparent;
      border-top: @arrow-width solid var(--app-background-color);
    }
  }

  .arrow-body {
    background-color: var(--app-background-color);
    margin: 0 auto;
    flex-grow: 1;
    width: @arrow-width;
    display: flex;
    align-items: center;

    .label {
      margin-top: -@arrow-width;
      .text {
        width: 14px; // center in Safari
        color: rgba(0, 0, 0, 0.4);
        font-weight: 600;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
      }
    }
  }
}

.flow-direction {
  .arrow {
    &.arrow-up {
      border-bottom: @arrow-width solid var(--app-primary-lightest);
    }

    &.arrow-down {
      border-top: @arrow-width solid var(--app-primary-lightest);
    }
  }
  .arrow-body {
    background-color: var(--app-primary-lightest);
  }
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;