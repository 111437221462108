@panel-header-footer-height: 250px;
@panel-left-width: 480px;
@panel-right-width: auto;

.layout-workspace-wrapper {
  display: flex;
  flex-direction: column;
}

.layout-workspace-header {
  height: 48px;
  margin-bottom: 16px;
}

.layout-workspace-footer {
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin: 16px 16px 0 16px;
}

.layout-workspace {
  display: grid;
  grid-template-columns: @panel-left-width @panel-right-width;
  gap: 8px;
  &.single-column {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

// scroll panel is also created in layout-step-panel to support scroll reset
.layout-workspace-left,
.layout-workspace-right {
  position: relative;
  width: 100%;
  height: calc(100vh - @panel-header-footer-height);
  overflow: hidden;
  overscroll-behavior-y: contain;
  // overflow-y: auto;
  &.hide {
    display: none;
  }
}

.layout-workspace-right {
  width: @panel-right-width;
}

.panel-control-container {
  position: absolute;
  right: 0;
  z-index: 100;
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;