@panel-header-footer-height: 250px;

.layout-step-panel {
  display: none;
  grid-template-columns: 1fr;
  gap: 8px;
  position: relative;
  height: calc(100vh - @panel-header-footer-height);
  overflow: hidden;
  overscroll-behavior-y: contain;
  overflow-y: auto;
  &.show {
    display: grid;
  }
}
@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;