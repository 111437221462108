.project-layout-wrapper {
  margin: 0 64px 16px 0;
}

.project-layout-header {
  margin: 16px 0;
}

// get colors from antd theme variables
.table-footer {
  margin-top: 16px;
  color: #2b2a35;
  opacity: 0.35;
  p {
    margin: 0;
  }
}

.link {
  color: #1890ff;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #40a9ff;
  }
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;