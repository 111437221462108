.layout-cards-wrapper {
  margin: 0 4rem 0 0;
}

.layout-cards-header {
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  gap: 12px;
  margin: 16px 0;
}

.layout-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 12px;
  margin: 12px 0;
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;