.layout-card {
  main {
    height: 56px;
    overflow: hidden;
  }
  footer {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    align-items: center;
    gap: 16px;
    font-size: 0.8rem;
  }
}
@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;