.layout-home-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
}

.layout-home-header {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  height: 55px;
  .logo {
    padding-left: 1rem;
  }
  .logo-text {
    margin-left: 8px;
    font-weight: 300;
    font-size: 1.25rem;
    color: #474747;
  }
}

.layout-home-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 1rem;
  align-items: center;
}

.layout-home-footer {
  margin: 1rem;
  text-align: center;
  font-size: 0.7rem;
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;