@import 'base.less';
@import 'amplify.less';

:root {
  --app-background-color: #f6f6f9;
  --app-border-color: #e9e9ef;
  --app-primary-color: #1890ff;
  --app-primary-tint: #77bfff;
  --app-primary-lighter: #afd9ff;
  --app-primary-lightest: #e7f4ff;
  --app-blue-gray: #a5a5c3;
  --app-blue-gray-dark: #8f8fb5;
  --app-blue-gray-darker: #8585ad;
  --app-blue-gray-darkest: #656597;
  --app-blue-gray-light: #bbbbd1;
  --app-blue-gray-lighter: #c5c5d9;
  --app-blue-gray-lightest: #e6e6ee;
  --app-error-background-color: #ffccc7;
  --app-error-border-color: #cc6666;
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;