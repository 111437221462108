
.section-layout {
  margin: 12px 0;
}

.section-heading {
  background-color: #fafafa;
  font-size: 15px;
  padding: 4px 12px;
}

.grid-panel {
  padding: 0 12px;
  margin: 0 0 !important;
}

.grid-section {
  padding: 0 12px;
}

.grid-col {
  flex: 0 0 200px;
}

.section-wrapper {
  margin-bottom: 16px;
  h4 {
    font-weight: 500;
    background-color: #fafafa;
    font-size: 15px;
    padding: 4px 12px;
  }
  &.columns {
    display: flex;
    flex-direction: row;
    gap: 8px;
    > div {
      flex-grow: 1;
    }
  }
  a {
    color: #40a9ff;
  }
}

.section-content {
  margin: 0 12px;
  &.columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
  }
}
@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;