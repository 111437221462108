.error-notification-box {
    width: 700px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff2f0;
    place-content: 'top-left';
    border-color: #ffccc7 !important;
    left: 100% !important;
}
.warning-notification-box {
    width: 700px;
    border-width: 1px;
    border-style: solid;
    background-color: #fffbe6;
    place-content: 'top-left';
    border-color: #ffb266 !important;
    left: 100% !important;
}
.cdf-number-error-notification-box {
    width: 700px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff2f0;
    place-content: 'top-left';
    border-color: #ffccc7 !important;
    left: 50% !important;
}
.costing-table .ant-table-thead > tr > th {
    text-align: center !important;  
}

.costing-notes-checkbox .ant-checkbox-disabled + span {
    color: #2b2a35;
}

.gross-margin-table {
    width: 100%;
    text-align: center;
    th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    tr td:nth-child(2) {
      text-align: left;
  }
}
@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;