@panel-width: 180px;
@hover-background-color: rgba(233, 233, 239, 0.5);

.layout-wrapper {
  width: 100%;
  height: 100%;

  .panel-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: @panel-width auto 1fr;
    height: 100%;
    width: calc(100%);
    left: 0;
    transition: all 150ms ease-out 0s;
    &.closed {
      width: calc(100% + @panel-width);
      left: -@panel-width;
    }
  }

  .panel-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 58px;
    border-bottom: 1px solid var(--layout-border-color);

    .panel-header-content {
      width: 100%;
      padding: 0 18px;
      display: flex;
      align-items: center;

      .panel-menu-header {
        width: 100%;
        padding: 8px;
        border-radius: 6px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
          background-color: @hover-background-color;
        }
      }
    }
  }

  .panel-control {
    margin-top: 16px;
  }

  .panel-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: @panel-width;
    height: 100%;
    flex-shrink: 0;
    transition: all 0.3s ease 0s;
    border-right: 1px solid var(--app-border-color);

    .panel-left-content {
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 16px;
    }

    .items-wrapper {
      margin: 8px 0;
      &.spacer {
        padding-top: 8px;
        border-top: 1px solid var(--app-border-color);
      }
      .item {
        display: flex;
        align-items: center;
        height: 36px;
        width: 100%;
        margin-bottom: 4px;
        padding: 0 12px;
        cursor: pointer;
        border: 2px solid transparent;
        border-radius: 6px;
        color: #2b2a35;
        &.active {
          background-color: var(--app-border-color);
        }
        &:hover {
          background-color: @hover-background-color;
        }
      }
    }
  }

  .panel-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .panel-main-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  .ant-tag.ant-tag-checkable {
    margin: 2px;
    &:not(.ant-tag-checkable-checked) {
      background: var(--app-border-color);
    }
  }
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;