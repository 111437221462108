.table-row-unselect {
  background-color: #ffffff;
}

.extrusion-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important;
}

.extrusion-table .ant-table-thead > tr > th {
  text-align: center !important;
}

.table-row-select {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  background-color: #1890ff18;
  color: #1890ff;
  font-weight: bold;
}

.table-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.filter-card {
  width: 'auto';
}

.filter-card .ant-card-actions > li {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.filter-input {
  margin-bottom: 4px;
  display: 'block',
}

.filter-label {
  margin-bottom: 4px;
  display: 'block',
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;