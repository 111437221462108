
.number-input-wrapper {
  position: relative;
  .number-input {
    display: grid;
    grid-template-columns: auto 1fr;
    .number-input-label {
      margin: 8px 0 4px;
      grid-column-start: span 2;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    //.value-input { }
    //.units-select { }
  }

  .error-message {
    z-index: 10;
    display: none;
    position: absolute;
    width: 190px;
    left: 0;
    right: 0;
    box-shadow: 0 7px 15px #ccc;
  }

  &.error {
    .number-input {
      .value-input input {
        color: #c00;
      }
    }
    .error-message {
      display: block;
    }
  }
}

// overwrite ant style
.number-input-wrapper.error .number-input .value-input input {
  background: #fff2f0;
}

@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;