html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #f6f6f9;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a:focus:not([data-focus-visible-added]),
button:focus:not([data-focus-visible-added]) {
  outline: none;
  box-shadow: none;
}
