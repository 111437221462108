.autosave-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  .autosave-status {
    font-size: 0.8rem;
    line-height: 1.75rem;
    margin-right: 16px;
  }
}
@heading-color: #2b2a35;@text-color: #2b2a35;@text-color-secondary: #1b1b35;@border-radius-base: 6px;